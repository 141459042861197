import Vue from 'vue'
import Router from 'vue-router'
import Home from '../pages/Home.vue'
import Subject from '../pages/Subject.vue'
import Unit from '../pages/Unit.vue'
import Class from '../pages/Class.vue'
import Detail from '../pages/Detail.vue'
import VuePageTransition from "vue-page-transition";

Vue.use(Router)
Vue.use(VuePageTransition);

//const env = 'local';
// const env = 'dev';
 const env = 'prd';

const localApi = {
    home: '/scripts/json/home.json',
    subject: '/scripts/json/subject-field.json',
    unit: '/scripts/json/unit.json',
    class: '/scripts/json/class.json',
    detail: '/scripts/json/detail.json',
}
const devUrl = 'https://dev.backend.try-it.preston-works.com';
let devApi = {
    home: 'https://dev.backend.try-it.preston-works.com' + '/api/subject_area',
    subject: devUrl + '/api/subject_field',
    unit: devUrl + '/api/unit',
    class: devUrl + '/api/classes',
    detail: devUrl + '/api/detail',
}

const prdUrl = 'https://try-admin.l-channel.net';
let prdApi = {
    home: prdUrl + '/api/subject_area',
    subject: prdUrl + '/api/subject_field',
    unit: prdUrl + '/api/unit',
    class: prdUrl + '/api/classes',
    detail: prdUrl + '/api/detail',
}

let api;
if (env === 'local') {
    api = localApi;
} else if (env === 'prd') {
    api = prdApi;
} else {
    api = devApi;
}


export default new Router({
    // デフォルトの挙動では URL に `#` が含まれる.
    // URL から hash を取り除くには `mode:history` を指定する
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                transition: 'fade',
                footer: false,
                api: api.home
            },
        },
        {
            path: '/subject',
            name: 'subject',
            component: Subject,
            meta: {
                transition: 'fade',
                footer: true,
                api: api.subject
            },
        },
        {
            path: '/unit',
            name: 'unit',
            component: Unit,
            meta: {
                transition: 'fade',
                footer: true,
                api: api.unit
            },
        },
        {
            path: '/class',
            name: 'class',
            component: Class,
            meta: {
                transition: 'fade',
                footer: true,
                api: api.class
            },
        },
        {
            path: '/detail',
            name: 'detail',
            component: Detail,
            meta: {
                transition: 'fade',
                footer: true,
                api: api.detail
            },
        },
    ]
})