<template>
  <ul class="footer__wrap" v-bind:class="'school_type_'+ this.school_type_id">
    <li>
      <a class="footer__button footer__button--home">
        <div v-on:click="releaseSubject()">
          <div class="footer__icon"></div>
          <span class="footer__text">HOME</span>
        </div>
      </a>
    </li>
    <li v-if="this.english">
      <a v-bind:class="this.subject_area_id===1 ? 'footer__active':''" class="footer__button footer__button--english">
        <div v-on:click="setSubject(1)">
          <div class="footer__icon"></div>
          <span class="footer__text">英語</span>
        </div>
      </a>
    </li>
    <li v-if="this.math">
      <a v-bind:class="this.subject_area_id===2 ? 'footer__active':''" class="footer__button footer__button--math">
        <div v-on:click="setSubject(2)">
          <div class="footer__icon"></div>
          <span class="footer__text">数学</span>
        </div>
      </a>
    </li>
    <li v-if="this.science">
      <a v-bind:class="this.subject_area_id===3 ? 'footer__active':''" class="footer__button footer__button--science">
        <div v-on:click="setSubject(3)">
          <div class="footer__icon"></div>
          <span class="footer__text">理科</span>
        </div>
      </a>
    </li>
    <li v-if="this.society">
      <a v-bind:class="this.subject_area_id===4 ? 'footer__active':''" class="footer__button footer__button--society">
        <div v-on:click="setSubject(4)">
          <div class="footer__icon"></div>
          <span class="footer__text">社会</span>
        </div>
      </a>
    </li>
    <li v-if="this.national">
      <a v-bind:class="this.subject_area_id===5 ? 'footer__active':''" class="footer__button footer__button--national">
        <div v-on:click="setSubject(5)">
          <div class="footer__icon"></div>
          <span class="footer__text">国語</span>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
import utils from "../partials/utils";
import store from "../store";

// ajaxは一旦使わない
const url = '/scripts/json/home.json';


export default {
  name: 'Home',
  data() {
    return {
      english: false,
      math: false,
      science: false,
      society: false,
      national: false,
    }
  },
  methods: {
    setValues() {
      if (this.school_type_id === 0) {
        this.english = this.h_english;
        this.math = this.h_math;
        this.science = this.h_science;
        this.society = this.h_society;
        this.national = this.h_national;
      } else {
        this.english = this.c_english;
        this.math = this.c_math;
        this.science = this.c_science;
        this.society = this.c_society;
      }
    },
    setSubject(subject_area_id) {
      // Utilsから学校タイプ名, 教科名を取得
      let school_type_name = utils.getSchoolTypeName(this.school_type_id);
      let subject_area_name = utils.getSubjectAreaName(subject_area_id);

      // vuexへセット
      store.commit('set_school_type_id', this.school_type_id);
      store.commit('set_school_type_name', school_type_name);
      store.commit('set_subject_area_id', subject_area_id);
      store.commit('set_subject_area_name', subject_area_name);

      this.$router.push({path: '/subject'}, () => {})
    },
    releaseSubject() {
      store.commit('set_school_type_id', 0);
      store.commit('set_school_type_name', "");
      store.commit('set_subject_area_id', 0);
      store.commit('set_subject_area_name', "");
      this.$router.push({path: '/'})
    }
  },
  computed: {
    school_type_id() {
      return store.state.school_type_id;
    },
    subject_area_id() {
      return store.state.subject_area_id;
    },
    c_english() {
      return store.state.c_english;
    },
    c_math() {
      return store.state.c_math;
    },
    c_science() {
      return store.state.c_science;
    },
    c_society() {
      return store.state.c_society;
    },
    h_english() {
      return store.state.h_english;
    },
    h_math() {
      return store.state.h_math;
    },
    h_science() {
      return store.state.h_science;
    },
    h_society() {
      return store.state.h_society;
    },
    h_national() {
      return store.state.h_national;
    },
  },
  mounted() {
    //this.getPosts();
    this.setValues();
  }
}
</script>