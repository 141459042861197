<template>
  <div class="detail" v-bind:class="'subject_'+ this.subject_area_id">
    <div class="header header__common">
      <h1> {{ this.school_type_name }} {{ this.subject_area_name }}</h1>
      <h2> {{ this.subject_field_name }}</h2>
      <h3> {{ this.unit_name }}</h3>
      <h4> {{ this.class_name }}</h4>
    </div>

    <div class="main">
      <video id="video" class="detail__video" controls  playsinline
             v-bind:poster="this.detail.thumbnail_url">
      </video>

      <iframe class="detail__gist" v-bind:src="detail.gist_url"></iframe>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import utils from "../partials/utils";
import Hls from "hls.js";

export default {
  name: 'Detail',
  data() {
    return {
      subject_field_name: "",
      detail: {
        playlist_url: "",
        thumbnail_url: "",
        gist_url: ""
      },
      hls: new Hls(),
    }
  },
  title() {
    let subject_field_name = utils.getSubjectFieldName(this.subject_name, this.field_name)
    return this.class_name + " - " + this.unit_name + " - " + subject_field_name + " - " + this.school_type_name + this.subject_area_name + " | " + this.$appName;
  },
  methods: {
    setSubjectFieldName() {
      this.subject_field_name = utils.getSubjectFieldName(this.subject_name, this.field_name)
    },
    getPosts() {
      const url = this.$route.meta.api;

      axios.get(url, {
        params: {
          school_type: this.school_type_id,
          id: this.class_id,
        }
      }).then((response) => {
        this.loading = false;
        this.detail = this.filter(response.data);
        // debug
        console.log(response.data);
      }).catch((error) => {
        console.log(error);
      });
    },
    getVideoUrl(playlist_raw) {
      axios.get(playlist_raw)
          .then((response) => {
            console.log(response.data);
            return response.data;
          }).catch((error) => {
        console.log(error);
      });
    },
    videoPlay(playlist_url) {
      const video = document.getElementById("video");
      let videoUrl = playlist_url;

      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(videoUrl);
        this.hls.attachMedia(video);
        //video.play();
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoUrl;
        video.addEventListener("canplay", () => {
          //video.play();
        });
      }
    },
    filter(raw) {
      raw = raw[0];
      //raw.playlist_url = this.getVideoUrl(raw.playlist_url);
      this.videoPlay( raw.playlist_url);
      return raw;
    }
  },
  computed: {
    school_type_id() {
      return store.state.school_type_id;
    },
    subject_area_id() {
      return store.state.subject_area_id;
    },
    school_type_name() {
      return store.state.school_type_name;
    },
    subject_area_name() {
      return store.state.subject_area_name;
    },
    subject_id() {
      return store.state.subject_id;
    },
    subject_name() {
      return store.state.subject_name;
    },
    field_id() {
      return store.state.field_id;
    },
    field_name() {
      return store.state.field_name;
    },
    unit_id() {
      return store.state.unit_id;
    },
    unit_name() {
      return store.state.unit_name;
    },
    class_id() {
      return store.state.class_id;
    },
    class_name() {
      return store.state.class_name;
    },
  },
  mounted() {
    this.getPosts();
    this.setSubjectFieldName();
  }
}
</script>