<template>
  <div class="subject subject-class" v-bind:class="'subject_'+ this.subject_area_id">
    <div class="header header__common">
      <h1> {{ this.school_type_name }} {{ this.subject_area_name }}</h1>
    </div>

    <div class="main" v-if="this.is_loaded">
      <a class="button"
         v-for="(item, index) in subjects" :key="index">
        <div v-on:click="setSubjectField(item.subject_id, item.subject_name, item.field_id, item.field_name)">
          {{ item.subject_field_name }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import utils from "../partials/utils"

export default {
  name: 'Subject',
  init() {
  },
  data() {
    return {
      subjects: null,
      is_loaded: false
    }
  },
  title() {
    return this.school_type_name + this.subject_area_name + " | " + this.$appName;
  },
  watch: {
    subject_area_id() {
      this.getPosts();
    }
  },
  methods: {
    getPosts() {
      const url = this.$route.meta.api;

      axios.get(url, {
        params: {
          school_type: this.school_type_id,
          subject_area_id: this.subject_area_id,
        }
      }).then((response) => {
        this.loading = false;
        this.subjects = this.filter(response.data);
        // debug
        // console.log(response.data);

      }).catch((error) => {
        console.log(error);
      });
    },
    filter(raw) {
      raw.forEach(function (item) {
        item.subject_field_name = utils.getSubjectFieldName(item.subject_name, item.field_name)
      });
      this.is_loaded = true;

      return raw;
    },
    // ボタン押下後、vuexへ値をセット
    setSubjectField(subject_id, subject_name, field_id, field_name) {
      store.commit('set_subject_id', subject_id);
      store.commit('set_subject_name', subject_name);
      store.commit('set_field_id', field_id);
      store.commit('set_field_name', field_name);
      this.$router.push({path: '/unit'})
    },
  },
  computed: {
    school_type_id() {
      return store.state.school_type_id;
    },
    subject_area_id() {
      return store.state.subject_area_id;
    },
    school_type_name() {
      return store.state.school_type_name;
    },
    subject_area_name() {
      return store.state.subject_area_name;
    },
  },
  mounted() {
    this.getPosts();
  },
  beforeRouteUpdate(to, from, next) {
    this.getPosts();
    next();
  }
}
</script>