<template>
  <div class="home">
    <div class="header">
      <h1 class="header__logo">
        <img class="header__logo__image" src="images/logo/tryit_logo.png" alt="トライ イット Try IT">
      </h1>
      <p class="header__copy">勉強の"分からない"を5分で解決！<br>Try IT(トライ イット)は、実力派講師陣による<br>永久0円の映像授業サービス</p>
    </div>

    <div class="main">
      <h2 class="">中学生向け映像授業</h2>
      <div>
        <a class="button button__english" v-if="this.c_english">
          <div class="button_text" v-on:click="setSubject(1,1)">英語</div>
        </a>
        <a class="button button__math" v-if="this.c_math">
          <div class="button_text" v-on:click="setSubject(1,2)">数学</div>
        </a>
        <a class="button button__science" v-if="this.c_science">
          <div class="button_text" v-on:click="setSubject(1,3)">理科</div>
        </a>
        <a class="button button__society" v-if="this.c_society">
          <div class="button_text" v-on:click="setSubject(1,4)">社会</div>
        </a>
      </div>
      <h2 class="">高校生向け映像授業</h2>
      <div>
        <a class="button button__english" v-if="this.h_english">
          <div class="button_text" v-on:click="setSubject(0,1)">英語</div>
        </a>
        <a class="button button__math" v-if="this.h_math">
          <div class="button_text" v-on:click="setSubject(0,2)">数学</div>
        </a>
        <a class="button button__science" v-if="this.h_science">
          <div class="button_text" v-on:click="setSubject(0,3)">理科</div>
        </a>
        <a class="button button__society" v-if="this.h_society">
          <div class="button_text" v-on:click="setSubject(0,4)">社会</div>
        </a>
        <a class="button button__national" v-if="this.h_national">
          <div class="button_text" v-on:click="setSubject(0,5)">国語</div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import utils from "../partials/utils";
import store from "../store";


export default {
  name: 'Home',
  init: function () {
  },
  title() {
    return this.$appName;
  },
  components: {},
  data() {
    return {
      subjects: false,
    }
  },
  methods: {
    getPosts() {
      const url = this.$route.meta.api;

      axios.get(url, {
        /*        headers: {
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
                  'responseType': 'json'
                }*/
      }).then((response) => {
        this.subjects = this.filter(response.data);
        // debug
        // console.log(response.data);
      }).catch((error) => {
        console.log(error);
      });
    },
    filter(raw) {
      //raw.j = undefined;
      //raw.h = undefined;
      //  raw = raw[0];
      let h = raw.h; // 高校
      let c = raw.j; // 中学

      store.commit('set_c_english', c.includes(1));
      store.commit('set_c_math', c.includes(2));
      store.commit('set_c_science', c.includes(3));
      store.commit('set_c_society', c.includes(4));
      store.commit('set_h_english', h.includes(1));
      store.commit('set_h_math', h.includes(2));
      store.commit('set_h_science', h.includes(3));
      store.commit('set_h_society', h.includes(4));
      store.commit('set_h_national', h.includes(5));

      return true;
    },
    setSubject(school_type_id, subject_area_id) {
      // Utilsから学校タイプ名, 教科名を取得
      let school_type_name = utils.getSchoolTypeName(school_type_id);
      let subject_area_name = utils.getSubjectAreaName(subject_area_id);

      // vuexへセット
      store.commit('set_school_type_id', school_type_id);
      store.commit('set_school_type_name', school_type_name);
      store.commit('set_subject_area_id', subject_area_id);
      store.commit('set_subject_area_name', subject_area_name);

      this.$router.push({path: '/subject'})
    }
  },
  computed: {
    c_english() {
      return store.state.c_english;
    },
    c_math() {
      return store.state.c_math;
    },
    c_science() {
      return store.state.c_science;
    },
    c_society() {
      return store.state.c_society;
    },
    h_english() {
      return store.state.h_english;
    },
    h_math() {
      return store.state.h_math;
    },
    h_science() {
      return store.state.h_science;
    },
    h_society() {
      return store.state.h_society;
    },
    h_national() {
      return store.state.h_national;
    },
  },
  mounted() {
    this.$parent.is_footer = false;
    this.getPosts();
  }
}
</script>