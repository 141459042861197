<template>
  <div class="unit subject-class" v-bind:class="'subject_'+this.subject_area_id">
    <div class="header header__common">
      <h1> {{ this.school_type_name }} {{ this.subject_area_name }}</h1>
      <h2> {{ this.subject_field_name }}</h2>
    </div>

    <div class="main" v-if="this.is_loaded">
      <a
          class="button"
          v-for="(item, index) in units"
          :key="index">
        <div v-on:click="setUnit(item.unit_id, item.unit_name)">
          {{ item.unit_name }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import utils from "../partials/utils"

export default {
  name: 'Unit',
  data() {
    return {
      units: null,
      is_loaded: false,
      subject_field_name: "",
    }
  },
  title() {
    let subject_field_name = utils.getSubjectFieldName(this.subject_name, this.field_name)
    return subject_field_name + " - " + this.school_type_name + this.subject_area_name + " | " + this.$appName;
  },
  methods: {
    setSubjectFieldName() {
      this.subject_field_name = utils.getSubjectFieldName(this.subject_name, this.field_name);
    },
    setUnit(unit_id, unit_name) {
      store.commit('set_unit_id', unit_id);
      store.commit('set_unit_name', unit_name);
      this.$router.push({path: '/class'})

    },
    getPosts() {
      const url = this.$route.meta.api;

      axios.get(url, {
        params: {
          school_type: this.school_type_id,
          subject_area_id: this.subject_area_id,
          subject_id: this.subject_id,
          field_id: this.field_id,
        }
      }).then((response) => {
        this.loading = false;
        this.units = this.filter(response.data);
        // debug
        //console.log(response.data);
      }).catch((error) => {
        console.log(error);
      });
    },
    filter(raw) {
      this.is_loaded = true;
      return raw;
    }
  },
  computed: {
    school_type_id() {
      return store.state.school_type_id;
    },
    subject_area_id() {
      return store.state.subject_area_id;
    },
    school_type_name() {
      return store.state.school_type_name;
    },
    subject_area_name() {
      return store.state.subject_area_name;
    },
    subject_id() {
      return store.state.subject_id;
    },
    subject_name() {
      return store.state.subject_name;
    },
    field_id() {
      return store.state.field_id;
    },
    field_name() {
      return store.state.field_name;
    },
  },
  mounted() {
    this.getPosts();
    this.setSubjectFieldName();
  }
}
</script>