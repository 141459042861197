import Vue from 'vue'
import App from './App.vue'
import router from './partials/router'
import store from "./store";
import title from './partials/title'

Vue.config.productionTip = false
Vue.mixin(title)

Vue.prototype.$appName = 'Try IT'

new Vue({
    store, // vuex
    router, // vue-router
    render: h => h(App),
}).$mount('#app')