import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        school_type_id: null,
        school_type_name: null,
        subject_area_id: null,
        subject_area_name: null,
        subject_id: null,
        subject_name: null,
        field_id: null,
        field_name: null,
        unit_id: null,
        unit_name: null,
        class_name: null,
        class_id: null,  // 行ID

        c_english: false,
        c_math: false,
        c_science: false,
        c_society: false,
        h_english: false,
        h_math: false,
        h_science: false,
        h_society: false,
        h_national: false,
    },
    getters: {},
    mutations: {
        set_school_type_id(state, value) {
            state.school_type_id = value;
        },
        set_school_type_name(state, value) {
            state.school_type_name = value;
        },
        set_subject_area_id(state, value) {
            state.subject_area_id = value;
        },
        set_subject_area_name(state, value) {
            state.subject_area_name = value;
        },
        set_subject_id(state, value) {
            state.subject_id = value;
        },
        set_subject_name(state, value) {
            state.subject_name = value;
        },
        set_field_id(state, value) {
            state.field_id = value;
        },
        set_field_name(state, value) {
            state.field_name = value;
        },
        set_unit_id(state, value) {
            state.unit_id = value;
        },
        set_unit_name(state, value) {
            state.unit_name = value;
        },
        set_class_name(state, value) {
            state.class_name = value;
        },
        set_class_id(state, value) {
            state.class_id = value;
        },

        set_c_english(state, value) {
            state.c_english = value;
        },
        set_c_math(state, value) {
            state.c_math = value;
        },
        set_c_science(state, value) {
            state.c_science = value;
        },
        set_c_society(state, value) {
            state.c_society = value;
        },
        set_h_english(state, value) {
            state.h_english = value;
        },
        set_h_math(state, value) {
            state.h_math = value;
        },
        set_h_science(state, value) {
            state.h_science = value;
        },
        set_h_society(state, value) {
            state.h_society = value;
        },
        set_h_national(state, value) {
            state.h_national = value;
        },

    },
    actions: {},
    plugins: [createPersistedState({
        key: 'try-it',
        storage: window.sessionStorage
    })],
});