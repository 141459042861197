<template>
  <div id="app">
    <vue-page-transition>
      <router-view></router-view>
    </vue-page-transition>
    <footer class="">
      <Footer v-if="this.footer"></Footer>
    </footer>
  </div>
</template>

<script>
import Footer from './components/Footer';
import liff from "@line/liff";

const liff_id = '1655096723-M7ydPlJV';

export default {
  components: {
    Footer
  },
  data() {
    return {
      userName: undefined,
      isInClient: undefined,
      isLoggedIn: false,
    }
  },
  created() {
    liff
        .init({
          liffId: liff_id
        })
        .then(() => {
          this.isLoggedIn = liff.isLoggedIn();
          if (!liff.isInClient() && !liff.isLoggedIn()) {
            liff.login();
          } else {
            this.isInClient = liff.isInClient();
            liff.getProfile()
                .then(profile => {
                  this.userName = profile.displayName;
                })
          }
        })
  },
  methods: {
    logout: function () {
      if (liff.isLoggedIn()) {
        liff.logout();
        location.href='/';
      }
    }
  },
  computed: {
    footer() {
      return this.$route.meta.footer;
    }
  }
};
</script>