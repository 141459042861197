// subject_area_id(教科ID)から教科名を取得
function getSubjectAreaName(subject_area_id) {
    subject_area_id = parseInt(subject_area_id);
    let subject_area_name = null;

    switch (subject_area_id) {
        case 1:
            subject_area_name = '英語'
            break;
        case 2:
            subject_area_name = '数学'
            break;
        case 3:
            subject_area_name = '理科'
            break;
        case 4:
            subject_area_name = '社会'
            break;
        case 5:
            subject_area_name = '国語'
            break;
    }

    return subject_area_name;
}

// school_type_id(区分ID)から学校タイプ名を取得
function getSchoolTypeName(school_type_id) {
    school_type_id = parseInt(school_type_id);
    let school_type_name = '';

    switch (school_type_id) {
        case 0:
            school_type_name = '高校';
            break;
        case 1:
            school_type_name = '中学';
            break;
    }
    return school_type_name;
}

//
function getSubjectFieldName(subject_name, field_name){
    let subject_field_name = "";
    if (subject_name === field_name) {
        subject_field_name = field_name;
    } else {
        subject_field_name = subject_name + " - " + field_name;
    }
    return subject_field_name;
}

export default {
    getSubjectAreaName,
    getSchoolTypeName,
    getSubjectFieldName
};